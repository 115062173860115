<template>
    <div class="v2-truth">
        <div class="title">진실성 담보 약정서</div>
        <p class="m-b-32" v-html="truth.title" />
        <div class="content">
            <div class="consent-item m-b-16" v-for="item in truth.content" :key="item.id">
                <div class="item">
                    <div class="num" v-html="`${item.id}.`" />
                    <div class="content" v-html="item.desc" />
                </div>
                <div v-if="item.content" class="indent">
                    <p class="indent truth-indent-item" v-for="val in item.content" v-html="val.id" :key="val.id" />
                </div>
            </div>
        </div>
        <div class="today m-t-16">
            <span class="m-r-16">계약일: </span>
            <span class="spoqa-f-bold">{{ `${today.y} 년 ${today.m} 월 ${today.d} 일` }}</span>
        </div>
        <div class="name sign-space">
            <div class="left">
                <span>회원성명: </span>
                <span class="m-l-16 spoqa-f-bold" v-html="me.name" />
            </div>
            <SignComponent :signStatus="signStatus" :sign="sign" :idx="1" id="truth-sign" />
        </div>
    </div>
</template>

<script>
import SignComponent from '@/components/app/SignComponent'

export default {
    name: 'ContractTruthV2',
    components: { SignComponent },
    props: {
        sign: String,
        today: Object,
        contractVersion: Object,
        signStatus: Object,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        truth() {
            return this.contractVersion.truth
        },
    },
}
</script>

<style scoped lang="scss">
.v2-truth {
    padding-bottom: 40px;

    .indent {
        margin-left: 16px;
    }
    .title {
        text-align: center;
        margin-bottom: 12px;
        font-size: 18px;
        @include spoqa-f-bold;
    }
    .item {
        display: flex;
        .num {
            width: 5%;
        }
        .content {
            width: 90%;
        }
    }
    .sign-space {
        display: flex;
        align-items: center;
        margin-top: 16px;
    }
}
</style>
